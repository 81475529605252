/*footer*/

.page-footer {
    /*background-color: #272431;*/
    /* background-color: #413b4a; */
    background-color: #000000ab;
    overflow: hidden;
    border-top-left-radius: 50% 20%;
    border-top-right-radius: 50% 20%;
}

.font-color {
    color: white;
}

.footer-sub-item {}

.footer-sub-item a {
    text-decoration: none;
    text-align: left;
    font-size: 14px;
}


/*back to top*/

#back-to-top-btn {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 26px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    outline: none;
    border: 3px solid #333;
    border-radius: 50%;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    transition-property: background-color, color;
}

#back-to-top-btn:hover,
#back-to-top-btn:focus {
    background-color: #333;
    color: #fff;
}

.horizontal-scroll {
    width: 100%;
    background: url('http://mckenziedave.co.uk/1st-touch/footer_fr.png');
    -webkit-animation: backgroundScroll 400s linear infinite;
    animation: backgroundScroll 60s linear infinite;
    background-repeat: no-repeat;
}

@-webkit-keyframes backgroundScroll {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -600px 0;
    }
}

@keyframes backgroundScroll {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -2000px 0;
    }
}

.horizontal-scroll2 {
    width: 100%;
    background: url('http://mckenziedave.co.uk/1st-touch/footer_bg.png');
    -webkit-animation: backgroundScroll2 400s linear infinite;
    animation: backgroundScroll2 30s linear infinite;
    background-repeat: no-repeat;
}

@-webkit-keyframes backgroundScroll2 {
    from {
        background-position: -1000px 0;
    }
    to {
        background-position: 0 0;
    }
}

@keyframes backgroundScroll2 {
    from {
        background-position: -2000px 0;
    }
    to {
        background-position: 0 0;
    }
}