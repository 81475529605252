header {
    background-color: white;
    border-bottom: var(--border-color);
    box-shadow: 1px 1px #dcb2b205;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    z-index: 11;
}

.super-top-bar {
    background-color: #f9f9f9;
    width: 100%;
}

.super-top-bar-content {
    width: 90%;
    display: flex;
    margin-left: auto;
}

.super-top-bar-phone {}

.super-top-bar-phone i {
    font-size: 0.9em;
    color: var(--text-color);
}

.super-top-bar-phone span {
    font-size: 0.9em;
    color: var(--text-color);
}

.super-top-bar-language {}

.super-top-bar-language i {
    font-size: 0.9em;
    color: var(--text-color);
}

.super-top-bar-language select {
    height: 1.3em;
    font-size: 0.7em;
    color: var(--text-color);
}

.super-top-bar-language select option {
    font-size: 0.9em;
    color: var(--text-color);
}

.top-bar {
    border-bottom: var(--border-color);
    box-shadow: 1px 1px #dcb2b205;
}

.menu-bar {
    /* background-color: var(--main-color-2); */
}

.logo-text {
    text-decoration: none;
    color: var(--main-color-2);
    position: relative;
    top: 20%;
    font-size: 2em;
}

.logo-text:hover {
    color: var(--main-color);
}

.search-section {
    padding: 1em 0em;
}

.search-bar {
    position: relative;
    top: 6%;
}

.nav-icon {
    position: relative;
    top: 25%;
}

.fa,
.fas {
    font-size: 1.8em;
    margin-left: 0.5em;
    color: var(--main-color-2);
}

.custom-form-control {
    border: 1px solid var(--main-color-2) !important;
}

.custom-form-control:focus {
    border: 1px solid var(--main-color-2) !important;
}

.custom-input-group-text {
    background-color: var(--main-color-2);
    color: white;
}

.search-icon {
    margin-left: 0;
    color: white;
}

.category {
    width: 100%;
    height: 100%;
}

.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    transition: 2s ease-in-out;
    height: 100%;
}

.menu li {
    height: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 0.8rem;
    /* Adjust the top padding size as needed */
    padding-bottom: 0.5rem;
    /* Adjust the bottom padding size as needed */
}

.menu li:hover {
    background-color: #F3F3F3;
}

.menu-items {
    float: left;
}

.menu-items-text {
    display: block;
    text-decoration: none;
    color: var(--text-color);
    text-align: center;
    padding: 0.6em 0.9em;
    font-weight: bold;
    font-size: 1.14rem;
}

.menu-icon {
    font-size: 1.4rem;
    margin-right: 0.4rem;
}

.menu-items-text:hover {
    color: #FF3F08 !important;
    /* background-color: #FF3F08; */
}

.menu-items:hover {
    /* background-color: #FF3F08; */
    color: #FF3F08 !important;
}

.bg-foregin-tiles {
    border: 0.1px solid #FF3F08;
    color: #FF3F08;
}

.slider-container {
    width: 100%;
    /* max-height: 700px; */
    height: 100vh;
    position: relative;
}

.slider-item-div {
    padding: 20px;
    background-color: white;
    text-align: center;
    height: 100%;
    width: 100%;
}

.carousel-style {
    height: 100% !important;
}

.slider-container img {
    display: block;
    width: 100%;
    /* max-height: 700px; */
    height: 100vh;
    object-fit: cover;
}

.slider-container-div {
    position: absolute;
    top: 38%;
    left: 42%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 1;
}

.toggle-category {
    font-size: 1.08em;
}

.colapse-subcategory {
    display: none;
    overflow: hidden;
}

.colapse-subcategory li {
    padding-left: 1.4em;
}

.category-active,
.toggle-category:hover {
    background-color: var(--main-color-2);
    color: white !important;
}

.subcat-icon {
    font-size: 1em;
}

.div-category-list {
    position: absolute;
    background-color: white;
    width: 82%;
    display: none;
    transition: 2s;
    border: var(--border-color);
}

.div-category-list:hover {
    display: block;
}

.category-name-byhover {
    font-size: 1em;
    font-weight: bold;
    color: #000000c2;
}

.category-name-byhover-list li {
    list-style: none;
    position: relative;
    left: -23%;
}

.category-name-byhover-list li a {
    text-decoration: none;
    font-size: 0.8em;
    font-weight: bold;
    color: #0000009c;
}

.category-name-byhover-list li a:hover {
    color: var(--main-color);
}

.main-content {}

.top-content {
    background-color: white;
    border: var(--border-color);
    min-height: 25em;
}

.carousel-item img {
    height: 25em;
}

.special-category {
    width: 100%;
    max-height: 250px;
    overflow: auto;
}

.special-category h6 {
    font-weight: bold;
}

.special-category ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.special-category ul li {
    border-bottom: var(--border-color);
}

.special-category ul li a {
    display: block;
    text-decoration: none;
    color: black;
    padding: 0.3em 0.4em;
}

.special-category ul li:hover {
    border: var(--border-color);
}

.special-category ul li a:hover {
    color: var(--main-color);
}

.carousel-caption {
    background-color: #1b0100a1;
}

.carousel-caption h5,
p {
    color: white;
}

.shop-by-category {}

.shop-by-category h5 {
    font-weight: bold;
}

.custom-card {
    background-color: white;
    border: var(--border-color);
    height: 160px;
    width: 100%;
}

.card-image {
    height: 70%;
    width: 100%;
}

.card-image img {
    width: 70%;
    height: 80%;
    margin: auto;
    display: block;
}

.card-all-text {
    text-align: center;
    color: black;
}

.card-all-text:hover {
    color: var(--main-color);
}

.product-card-wrapper {
    text-decoration: none;
}

.product-card-wrapper:hover {
    /* box-shadow: -15px 0 70px -15px #504bff, 15px 0 70px -15px #4cfa63; */
}

.product-card {
    background-color: white;
    border: var(--border-color);
    max-height: 92%;
    width: 100%;
    transform: translateX(0) translateY(0) rotate(0);
}

.card-top-left {
    width: 100%;
    border: var(--border-color);
}

.card-top-left i {
    font-size: 1em;
    color: #bd0f0fcc;
    display: inline;
}

.card-top-left span {
    color: var(--text-color);
}

.card-top-right {
    width: 100%;
    border: var(--border-color);
}

.card-top-right i {
    font-size: 1em;
    color: #bd0f0fcc;
    display: inline;
}

.card-top-right span {
    color: var(--text-color);
}

.card-offer {
    font-size: 0.7em;
    color: var(--main-color);
}

.product-card:hover {
    box-shadow: -15px 0 70px -15px #ffffff, 15px 0 70px -15px #ffffff !important;
}

.product-card-image {
    height: 75%;
    width: 100%;
    overflow: hidden;
}

.product-card-image img {
    width: 100%;
    height: 13em;
    margin: auto;
    display: block;
    transition: 1s;
    object-fit: cover;
}

.gallery-card-image {
    width: 100%;
    height: 16em;
    margin: auto;
    display: block;
    transition: 1s;
}

.product-card-image img:hover {
    transform: scale(1.2);
}

.card-top-content {
    position: relative;
    border-bottom: 1px solid #0000001f;
}

.cart-icon-card {
    text-decoration: none;
}

.cart-icon-love {
    text-decoration: none;
}

.card-info {
    width: 92%;
    margin: auto;
    position: relative;
}

.product-name {
    color: black;
    font-size: 0.9em;
    position: relative;
    top: -1.5em;
}

.text-of-product {
    text-decoration: none;
    color: black;
    font-size: 0.8em
}

.text-of-product:hover {
    color: var(--main-color);
}

.product-name:hover {
    color: var(--main-color);
}

.product-price {
    color: black;
    font-size: 0.8em;
}

.product-price:hover {
    color: var(--main-color);
}

.card-icon {
    width: 100%;
    position: relative;
}

.card-icon a {}

.product-name-2 {
    font-size: 1.1em;
    position: relative;
    top: -2.5em;
    color: var(--text-color) !important;
}

.product-name-3 {
    position: relative;
    top: -4.5em;
    color: var(--text-color);
}

.product-name-4 {
    position: relative;
    top: -4.5em;
}

.product-name-4 select {
    width: 90%;
    border: var(--border-color);
    color: var(--text-color);
}

.product-name-4 select option {
    border: var(--border-color);
}

.delivery-info {
    position: relative;
    top: -3.5em;
}

.delivery-info i {
    font-size: 0.9em;
}

.delivery-info span {
    font-size: 0.7em;
    color: var(--text-color);
    position: relative;
    left: 0.2em;
}

.three-bar {
    display: none;
}

.sideNavOpen {
    height: 100%;
    width: 17em;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    transition: 0.1s;
    z-index: 10;
    background-color: white;
}

.sideNavClose {
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    overflow-x: hidden;
    transition: 0.1s;
    background-color: white;
}

.contentOverLay {
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
}

.sideMenuItem {
    height: 100vh;
}

.sidemenu-content {
    position: relative;
    top: 6em;
}

.sidemenu-profile-section {
    width: 100%;
    border-bottom: var(--border-color);
}

.sidemenu-profile-section-img {
    width: 40%;
    margin: auto;
}

.sidemenu-profile-section-img img {
    width: 100%;
}

.sidemenu-profile-section-name {
    width: 100%;
}

.sidemenu-profile-section-name h6 {
    text-align: center;
    font-size: 1.3em;
}

.sidemenu-menu-items {}

.sidemenu-menu-items ul {}

.sidemenu-menu-items ul li {
    list-style: none;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: var(--border-color);
    margin-left: -10%;
}

.sidemenu-menu-items ul li a {
    text-decoration: none;
    color: #423b3b;
    font-size: 1.2em;
    position: relative;
    left: 6%;
}

.sidemenu-menu-items ul li a:hover {
    color: var(--main-color);
}

#downArrow {
    font-size: 1.2em;
    position: relative;
    left: 10%;
}

#upArrow {
    font-size: 1.2em;
    position: relative;
    left: 10%;
}

#categorySubmenu {
    position: relative;
    left: 15%;
}

.category-list-sub-menu {}

.category-list-sub-menu ul {}

.category-list-sub-menu ul li {}

.category-list-sub-menu ul li a {
    font-size: 1.2em;
}

.carousel-indicators button {
    background-color: #e20d02c9;
    ;
    border: none;
    color: white;
    padding: 0.6em 1.5em;
}

.explore-section {
    background-color: #F4F4F4;
}

.explore-section-left {
    position: relative;
    top: 12%;
    /* transform: translateY(-45%); */
}

.explore-section-left h6 {}

.explore-section-left h3 {}

.explore-section-left p {}

.explore-section-right {}

.explore-section-right-image {
    width: 100%;
    height: 270px;
}

.explore-section-right-image-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}

.middle-slider-section {
    background-color: #EFEEEF;
    position: relative;
}

.middle-slider-section-left {
    position: relative;
    top: 45%;
    transform: translateY(-45%);
}

.middle-slider-section-left h6 {}

.middle-slider-section-left h3 {
    color: #212529;
}

.middle-slider-section-left p {}

.middle-slider-section-right {}

.middle-slider-section-left-div {
    margin: 70px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 1;
}

.w-60 {
    width: 70%;
}

.middle-slider-right {
    width: 100%;
}

.middle-slider-right img {
    width: 82%;
    margin-top: -15%;
}

.shop-by-category {
    position: relative;
    background: #736666;
    padding: 40px 25px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.801);
    /* Adjust the opacity here */
}

.shop-by-category h3 {
    position: inherit;
    text-align: center;
    z-index: 1 !important;
}

.show-case-section {
    background-color: #F4F4F4;
}

.show-case-section-left {
    position: relative;
    top: 45%;
    transform: translateY(-45%);
}

.show-case-section-left h6 {}

.show-case-section-left h3 {}

.show-case-section-left p {}

.show-case-section-right {}

.show-case-section-right-image {
    width: 100%;
    height: 200px;
}

.show-case-section-right-image-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}

.right-side-menu {
    color: #736666;
    text-decoration: none;
    margin: 5px;
}

.right-side-menu i {
    font-size: 1rem;
    margin: 8px;
}

.logo-img {
    width: 200px;
    height: 87px;
    margin-top: -17px;
}

.contact-us-section {
    position: relative;
}

.contact-us-section-left {
    position: relative;
    top: 45%;
    transform: translateY(-45%);
}

.contact-us-section-left h6 {}

.contact-us-section-left h3 {
    color: #212529;
}

.contact-us-section-left p {}

.contact-us-section-right {}

.contact-us-section-left-div {
    margin: 70px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 1;
}

.w-60 {
    width: 80%;
}

.contact-us-right {
    margin-top: 30px;
    width: 70%;
}

.middle-slider-section-d-block {
    display: block;
}

.middle-slider-section-d-none {
    display: none;
}

.w-120 {
    width: 130% !important;
    height: 600px;
}

.slide {
    z-index: 1 !important;
}

.carousel .control-dots {
    z-index: 2 !important;
}

.carousel .slide .legend {
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #00000000;
    color: #fff;
    padding: 10px;
    font-size: 1.3rem;
    text-align: center;
    opacity: 0.25;
    transition: opacity .35s ease-in-out;
}

.carousel .control-next.control-arrow:before {
    border-left: 25px solid #000000 !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 25px solid #000000 !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 28px solid transparent !important;
    border-bottom: 28px solid transparent !important;
    content: '';
}

.updated-menu {
    position: fixed;
    width: 100%;
    color: white;
    transition: 2s;
    background-color: rgba(23, 23, 23, 0.63);
    transition: 2s;
}

.menu-updated-item {
    margin-top: 10px !important;
    color: white;
}

.menu-item-updated {
    float: left;
    /* margin-top: 12px !important; */
}

.logo-img-updated {
    width: 200px;
    height: 75px;
    /* margin-top: -17px; */
}

.phone-call-options {
    position: absolute;
    right: 0;
    top: 23%;
    z-index: 111;
    border: 1px solid #ffffff;
    border-radius: 0px;
    border-right: none;
    transition: 1s;
    display: flex;
}

.custom-phone-btn {
    border-radius: 0px;
    border-right: none;
    transition: 0.4s;
}

.phone-call-options span {
    color: white;
    transition: 0.4s;
    display: none;
    width: 0px;
}

.phone-option-icon {
    color: white;
    font-size: 1.3rem;
}

.phone-call-options:hover span {
    display: block;
    transition: 1s;
    padding-top: 7px;
    width: 46px;
    color: white;
}

.social-icon {
    position: absolute;
    bottom: 60px;
    left: 3%;
    z-index: 9 !important;
}

.socialicon .social-link:not(:last-child) {
    margin-right: 20px;
}

.slider-social-link-image {
    width: 2rem;
    margin-right: 10px;
}

nav.link-list {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 42%;
    display: inline-block;
    z-index: 111;
}

nav.link-list li {
    overflow: hidden;
    float: left;
    color: #ffffff;
}

nav.link-list a {
    position: relative;
    color: #ffffff;
    text-decoration: none;
}

nav.link-list a h3 {
    color: #ffffff;
    font-size: 40px;
    margin: 0;
}

.find__btn {
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 18px !important;
    align-items: center !important;
    text-align: center !important;
    text-transform: uppercase !important;
    color: #ffffff !important;
    background: #FF3F08 !important;
    border: 1px solid #FF3F08 !important;
    border-radius: 0px !important;
    padding: 10px !important;
    margin-top: 10px !important;
    position: relative !important;
    transition: all 0.35s !important;
    left: 38%;
}

.find__btn span {
    position: relative;
    z-index: 2;
}

.find__btn:hover {
    color: #fff;
    border-color: #2a3c50;
    box-shadow: none;
}

.home-slider-h3 {
    background: #0000007d;
    padding: 1rem;
}

.bg-custom-selected {
    background-color: #ff3d0823 !important;
    color: white !important;
}

.list-group-item {
    border: none !important;
}

.foreign-tiles-option {
    position: absolute;
    left: 0;
    top: 15%;
    z-index: 7;
    background-color: #FF5524;
    color: white;
    border-right: none;
    transition: 1s;
    display: flex;
}

.foreign-tiles-option:hover {
    display: block;
    transition: 1s;
    padding-top: 7px;
    color: white;
}

.foreign-tiles-a {
    color: white;
    text-decoration: none;
    padding: 1rem 1rem;
    text-decoration: none;
    background-color: #FF440F;
    border: 0;
    width: 100%;
    border: #FF440F;
}

.foreign-tiles-a:hover {
    display: block;
    transition: 1s;
    padding-top: 7px;
    color: white;
}

.explosive-title-cali {
    font-size: 1.8rem !important;
    font-weight: bold;
}

.explosive-title-cali-a {
    text-decoration: none;
}

.carousel-status {
    display: none !important;
}

.carousel .thumbs-wrapper {
    margin: 10px;
    overflow: initial;
}

.styles-module_wrapper__1I_qj {
    z-index: 12 !important;
}

.foreign-tiles-menu-text a {
    color: #FF4F1C !important;
    font-weight: bold;
}

.foreign-tiles-menu-text a:hover {
    color: white !important;
    background-color: #FF4F1C;
    font-weight: bold;
}

.storelogo {
    width: 5rem;
    height: 5rem;
}

.scrollToBtn {
    position: fixed;
    width: 85%;
    left: 55%;
    bottom: 60px;
    height: 31px;
    font-size: 13rem;
    z-index: 1;
    cursor: pointer;
    color: #FF4F1C;
}

.store-logo-margin {
    margin-top: 3rem !important;
}

.branch-title {}

.empty-state {
    width: 100%;
    /* margin: 40px auto; */
    background: #ffffff;
    box-shadow: 1px 2px 10px #e1e3ec;
    border-radius: 4px;
}

.empty-state__content {
    padding: 48px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.empty-state__icon {
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    border-radius: 200px;
    justify-content: center;
    background-color: #f7fafc;
    box-shadow: 0px 2px 1px #e1e3ec;
}

.empty-state__icon img {
    width: 170px;
}

.empty-state__message {
    color: #626463;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 0.85rem;
}

.empty-state__help {
    color: #a2a5b9;
    font-size: 0.875rem;
}

.credit {
    color: #A2A5B9;
    font-size: .75rem;
    text-align: center;
}

.credit a {
    color: #444;
}

.load-more-div {
    width: 65%;
    height: 83%;
}

.load-more-font-size {
    font-size: 1.6rem;
}

.custom-modal .modal-dialog .modal-content {
    background-color: transparent;
    border: none;
}

.custom-modal .modal-dialog .modal-content .modal-header {
    border: none;
}

.modal-content {
    position: relative !important;
    background-color: #fff !important;
    -webkit-background-clip: padding-box !important;
    background-clip: padding-box !important;
    border: 1px solid #999 !important;
    border: 1px solid rgba(0, 0, 0, .2) !important;
    border-radius: 0 !important;
    outline: 0 !important;
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5) !important;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5) !important;
}

.see-more-icon {
    font-size: 2rem;
}

.see-mnore-text {
    display: block;
    margin-top: 0.5rem !important;
}

.home-foreign-tiles-card-container {
    width: 100%;
    overflow-x: scroll;
    display: flex;
    padding: 3rem;
}

.home-foreign-tiles-card-container-2 {
    /* width: 100%;
            overflow-x: scroll; */
    display: flex;
    padding: 3rem;
}

.card__content {}

.card__category {
    color: var(--text-color);
    font-size: 0.8rem;
    margin-bottom: var(--spacing-s);
    text-transform: uppercase;
}

.card__heading {
    color: var(--text-color);
    font-size: 1.2rem;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
}

.home-foreign-tiles-card {
    min-width: 300px;
    height: 450px;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: -1rem 0 3rem #cdcdcd;
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    transition: 0.2s;
}

.home-foreign-tiles-card-heading {
    color: #929DB2;
    font-size: 1.9rem;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    line-height: 1.4;
    word-spacing: 100vw;
}

.home-foreign-tiles-date {
    color: #8a8a8a;
}

.home-foreign-tiles-tags {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0 1rem;
}

.home-foreign-tiles-tags div {
    font-size: 9px;
    font-weight: 700;
    padding: 4px 10px;
    border: 3px solid #28242f;
    border-radius: 2rem;
}

.home-foreign-tiles-card .home-foreign-tiles-category-title {
    font-size: 14px;
    color: #fff;
    margin-bottom: 1rem;
}

.home-foreign-tiles-card .home-foreign-tiles-article-title {
    font-size: 20px;
    font-weight: 700;
}

.home-foreign-tiles-card .home-foreign-tiles-article-header {
    margin-bottom: auto;
}

.home-foreign-tiles-card .home-foreign-tiles-author {
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 75px 1fr;
}

.home-foreign-tiles-author .home-foreign-tiles-info .home-foreign-tiles-caption {
    color: #8a8a8a;
}

.home-foreign-tiles-card:not(:first-child) {
    margin-left: -130px;
}

.home-foreign-tiles-card:hover {
    transform: translateY(-1rem);
}

.home-foreign-tiles-card:hover~.home-foreign-tiles-card {
    transform: translateX(130px);
}

.loadedFade {
    transform: translateX(100vw) translateY(100vh) rotate(15deg);
    transition: all 1s ease-out;
}

.loadedFade.loadedFadedContent {
    opacity: 0;
    transition: all 2s ease-out;
}

.loadedFade.loaded {
    opacity: 1;
    transform: translateX(0) translateY(0) rotate(0);
}

.loadedFade.loaded .loadedFadedContent.loaded {
    opacity: 1;
}


/* Slideshow Container */

.slideshow-h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 99;
    text-align: center;
    font-family: Raleway, sans-serif;
    font-weight: 300;
    text-transform: uppercase;
    background-color: rgba(255, 255, 255, 0.75);
    box-shadow: 0 1em 2em -1em rgba(0, 0, 0, 0.5);
    padding: 1em 2em;
    line-height: 1.5;
}

.slideshow-h1 .slideshow-small {
    display: block;
    text-transform: lowercase;
    font-size: 0.7em;
}

.slideshow-h1 .slideshow-small:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding-bottom: 0.5em;
}

.slideshow-h1 .slideshow-small:last-child {
    border-top: 1px solid rgba(0, 0, 0, 0.25);
    padding-top: 0.5em;
}

.image-wrap {
    overflow: hidden;
    animation: move 10s ease infinite;
    /* Add infinite to loop. */
    -ms-animation: move 10s ease infinite;
    -webkit-animation: move 10s ease infinite;
    animation: move 10s ease infinite;
    -moz-animation: move 10s ease infinite;
}

@keyframes move {
    0% {
        -webkit-transform-origin: bottom left;
        -moz-transform-origin: bottom left;
        -ms-transform-origin: bottom left;
        -o-transform-origin: bottom left;
        transform-origin: bottom left;
        transform: scale(1.0);
        -ms-transform: scale(1.0);
        /* IE 9 */
        -webkit-transform: scale(1.0);
        /* Safari and Chrome */
        -o-transform: scale(1.0);
        /* Opera */
        -moz-transform: scale(1.0);
        /* Firefox */
    }
    100% {
        transform: scale(1.1);
        -ms-transform: scale(1.1);
        /* IE 9 */
        -webkit-transform: scale(1.1);
        /* Safari and Chrome */
        -o-transform: scale(1.1);
        /* Opera */
        -moz-transform: scale(1.1);
        /* Firefox */
    }
}

.hero-section-category {
    align-items: flex-start;
    background-image: linear-gradient(15deg, #ffffff 0%, #ffffff 150%);
    display: flex;
    min-height: 100%;
    justify-content: center;
    padding: 64px 24px;
}

.card-grid-category {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    max-width: 1200px;
    width: 100%;
}

@media(min-width: 540px) {
    .card-grid-category {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(min-width: 960px) {
    .card-grid-category {
        grid-template-columns: repeat(4, 1fr);
    }
}

.card-category {
    list-style: none;
    position: relative;
    height: 200px;
}

.card-category:before {
    content: '';
    display: block;
    padding-bottom: 150%;
    width: 100%;
}

.card__background-category {
    background-size: cover;
    background-position: center;
    border-radius: 24px;
    bottom: 0;
    filter: brightness(0.75) saturate(1.2) contrast(0.85);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    trsnsform: scale(1) translateZ(0);
    transition: filter 200ms linear, transform 200ms linear;
}

.card-category:hover .card__background-category {
    transform: scale(1.05) translateZ(0);
}

.card-grid-category:hover>.card-category:not(:hover) .card__background-category {
    filter: brightness(0.5) saturate(0) contrast(1.2) blur(20px);
}

.card__content-category {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
}

.card__category-category {
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.9rem;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.card__heading-category {
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.9rem;
    text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
    line-height: 1.4;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
}

* {
    transition: all 0.9s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.intro p {
    color: #fff;
    text-align: center;
    display: block;
    width: 100%;
}

.intro a {
    color: #88bcf5;
    border-bottom: 2px solid transparent;
}

.intro a:hover {
    text-decoration: none;
    border-bottom: 2px solid #88bcf5;
}

.paracard {
    width: 100%;
    display: block;
    padding-top: 150%;
    transform-style: preserve-3d;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    border: 0px;
    background-size: 110% 110%;
    background-position: center center;
}

.center-paracard-image {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 95%;
    max-height: 95%;
}

.paracard-2 {
    padding-top: 95% !important;
}

.dcard {
    position: relative;
    display: block;
    perspective: 500px;
    border-radius: 12px;
}

.col-md-12,
.col-md-4 {
    margin-bottom: 30px;
}

.col-md-12 .dcard {
    perspective: 3000px;
}

.frame {
    background: rgba(0, 0, 0, 0.2);
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.frame:after {
    width: calc(100% - 20px);
    border: 2px solid rgba(255, 255, 255, 0.185);
    height: calc(100% - 20px);
    content: "";
    display: block;
    left: 10px;
    top: 10px;
    position: absolute;
    transform: translateZ(30px);
}

.dcard:hover .paracard .frame {
    background: rgba(0, 0, 0, 0.0);
}

.dcard:hover .paracard .frame:after {
    border: 2px solid rgba(255, 255, 255, 0.8);
}

.frame h2 {
    width: 98%;
    color: #fff;
    position: absolute;
    bottom: 10%;
    text-align: center;
    font-size: 24px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
}

.frame h6 {
    width: 98%;
    color: #fff;
    position: absolute;
    bottom: 5%;
    text-align: center;
    font-size: 18px;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
}

.trigger {
    position: absolute;
    height: 33.333333%;
    width: 33.333333%;
    display: block;
    z-index: 2;
}

.trigger:nth-child(1) {
    left: 0%;
    top: 0%;
}

.trigger:nth-child(1):hover~.paracard {
    transform: rotateY(20deg) rotateX(-10deg);
    background-position: top left;
}

.trigger:nth-child(2) {
    left: 33.333333%;
    top: 0%;
}

.trigger:nth-child(2):hover~.paracard {
    transform: rotateY(10deg) rotateX(-20deg);
    background-position: top center;
}

.trigger:nth-child(3) {
    left: 66.666666%;
    top: 0%;
}

.trigger:nth-child(3):hover~.paracard {
    transform: rotateY(-15deg) rotateX(-5deg);
    background-position: top right;
}

.trigger:nth-child(4) {
    left: 0%;
    top: 33.333333%;
}

.trigger:nth-child(4):hover~.paracard {
    transform: rotateY(15deg);
    background-position: center left;
}

.trigger:nth-child(5) {
    left: 33.333333%;
    top: 33.333333%;
}

.trigger:nth-child(5):hover~.paracard {
    transform: rotateY(9deg) rotateX(-9deg);
    background-position: center center;
}

.trigger:nth-child(6) {
    left: 66.666666%;
    top: 33.333333%;
}

.trigger:nth-child(6):hover~.paracard {
    transform: rotateY(-15deg) rotateX(5deg);
    background-position: center right;
}

.trigger:nth-child(7) {
    left: 0%;
    top: 66.666666%;
}

.trigger:nth-child(7):hover~.paracard {
    transform: rotateY(15deg) rotateX(10deg);
    background-position: bottom left;
}

.trigger:nth-child(8) {
    left: 33.333333%;
    top: 66.666666%;
}

.trigger:nth-child(8):hover~.paracard {
    transform: rotateY(0deg) rotateX(5deg);
    background-position: bottom center;
}

.trigger:nth-child(9) {
    left: 66.666666%;
    top: 66.666666%;
}

.trigger:nth-child(9):hover~.paracard {
    transform: rotateY(-18deg) rotateX(15deg);
    background-position: bottom right;
}

.buttons {
    width: 100%;
}

.buttons a {
    color: #88bcf5;
    border-bottom: 2px solid transparent;
    margin: 10px 20px;
}

.buttons a:hover {
    text-decoration: none;
    border-bottom: 2px solid #88bcf5;
}

.modal-dialog {
    /* width: 750px !important; */
    margin: 50px auto 0;
}

.modal.fade .modal-dialog {
    transform: scale(0);
    transition: all 400ms cubic-bezier(.47, 1.64, .41, .8);
}

.modal.in .modal-dialog {
    transform: scale(1);
}

.modal-dialog .modal-content {
    background: #fff;
    text-align: center;
    text-transform: uppercase;
    border-radius: 30px !important;
    border: none;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3) !important;
    overflow: hidden;
    width: 50rem !important;
    margin: 20% auto 0 !important;
}

.modal-dialog .modal-content .close {
    color: #fff;
    background-color: #111;
    font-size: 32px;
    font-weight: 500;
    text-shadow: none;
    line-height: 25px;
    height: 27px;
    width: 27px;
    padding: 0;
    border-radius: 50%;
    opacity: 1;
    overflow: hidden;
    position: absolute;
    left: auto;
    right: 15px;
    top: 15px;
    z-index: 2;
    transition: all 0.3s ease 0s;
}

.modal-dialog .modal-content .close span {
    margin: -2px 0 0 0;
    display: block;
}

.modal-content .close:hover {
    color: #747474;
}

.modal-dialog .modal-content .modal-body {
    padding: 0 !important;
}

.modal-dialog .modal-content .modal-body .modal-image {
    padding: 0 !important;
}

.modal-dialog .modal-content .modal-body .content {
    padding: 36px 30px;
}

.iiz {
    height: 100%;
}

.iiz div {
    height: 100%;
}

.iiz__img {
    max-height: 100% !important;
    /* min-width: 100% !important; */
    max-width: 100% !important;
    position: relative;
    /* top: 28%; */
    margin: 0;
    /* position: absolute; */
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    max-width: 95%;
    max-height: 95%;
}

.modal-dialog .modal-content .modal-body .title {
    color: #cecece;
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 30px 0;
}

.modal-dialog .modal-content .modal-body .sub-title {
    font-size: 45px;
    font-weight: 500;
    display: block;
    letter-spacing: 2px;
}

.modal-dialog .modal-content .modal-body .description {
    font-size: 20px;
    font-weight: 400;
    margin: 0 0 30px 0;
}

.modal-dialog .modal-content .modal-body .btn {
    color: #fff;
    background-color: #fb3640;
    font-size: 20px;
    font-weight: 400;
    width: 85%;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 0;
    padding: 10px;
    border: none;
    margin: 0 0 20px;
    transition: all 0.4s ease 0s;
}

.modal-dialog .modal-content .modal-body .btn:hover {
    color: #fff;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
}

.modal-dialog .modal-content .modal-body .btn.cancel {
    color: #353535;
    background-color: #eaeaea;
    display: block;
    margin: 0 auto 30px;
}

.modal-dialog .modal-content .modal-body .btn.cancel:hover {
    color: #353535;
    background-color: #eaeaea;
}

.modal-dialog .modal-content .modal-body .offer {
    color: #aaa;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .paracard-2 {
        padding-top: 75% !important;
    }
    .modal-dialog .modal-content {
        background: #fff;
        text-align: center;
        text-transform: uppercase;
        border-radius: 30px !important;
        border: none;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.3) !important;
        overflow: hidden;
        width: 95% !important;
    }
    .modal-dialog .modal-content .modal-body .modal-image {
        height: 250px;
        overflow: hidden;
    }
    .modal-dialog .modal-content .modal-body .modal-image img {
        height: auto;
    }
    .modal-dialog .modal-content .modal-body .content {
        text-align: center;
        padding: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .paracard-2 {
        padding-top: 75% !important;
    }
    .modal-dialog .modal-content {
        background: #fff;
        text-align: center;
        text-transform: uppercase;
        border-radius: 30px !important;
        border: none;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.3) !important;
        overflow: hidden;
        width: 99% !important;
    }
}

@media only screen and (min-width: 992.98px) {
    .paracard-2 {
        padding-top: 75% !important;
    }
    .modal-dialog .modal-content {
        background: #fff;
        text-align: center;
        text-transform: uppercase;
        border-radius: 30px !important;
        border: none;
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.3) !important;
        overflow: hidden;
        margin: 20% -15% 0 !important;
    }
}


/* category carousle */

.category-carousel {
    position: relative;
    z-index: 1;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
}

.category-carousel-item {
    --items: 10;
    --width: clamp(150px, 30vw, 300px);
    --height: clamp(200px, 40vw, 400px);
    --x: calc(var(--active) * 800%);
    --y: calc(var(--active) * 200%);
    --rot: calc(var(--active) * 120deg);
    --opacity: calc(var(--zIndex) / var(--items) * 3 - 2);
    overflow: hidden;
    position: absolute;
    z-index: var(--zIndex);
    width: var(--width);
    height: var(--height);
    margin: calc(var(--height) * -0.5) 0 0 calc(var(--width) * -0.5);
    border-radius: 10px;
    top: 50%;
    left: 50%;
    user-select: none;
    transform-origin: 0% 100%;
    box-shadow: 0 10px 50px 10px rgba(0, 0, 0, 0.5);
    background: black;
    pointer-events: all;
    transform: translate(var(--x), var(--y)) rotate(var(--rot));
    transition: transform 0.8s cubic-bezier(0, 0.02, 0, 1);
}

.category-carousel-item .category-carousel-box {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
    opacity: var(--opacity);
    font-family: 'Orelo-sw-db', serif;
}

.category-carousel-item .category-carousel-box:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5));
}

.category-carousel-item .category-title {
    position: absolute;
    z-index: 1;
    color: #fff;
    bottom: 20px;
    left: 20px;
    transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
    font-size: clamp(35px, 3vw, 30px);
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.category-carousel-item .category-num {
    position: absolute;
    z-index: 1;
    color: #fff;
    top: 10px;
    left: 20px;
    transition: opacity 0.8s cubic-bezier(0, 0.02, 0, 1);
    font-size: clamp(20px, 10vw, 80px);
}

.category-carousel-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
}

.category-cursor {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    --size: 40px;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    margin: calc(var(--size) * -0.5) 0 0 calc(var(--size) * -0.5);
    transition: transform 0.85s cubic-bezier(0, 0.02, 0, 1);
    display: none;
    pointer-events: none;
}

@media (pointer: fine) {
    .category-cursor {
        display: block;
    }
}

.category-cursor2 {
    --size: 2px;
    transition-duration: 0.7s;
}