        /* Extra small devices (portrait phones, less than 576px)*/
        
        @media(max-width: 280px) {
            .branch-title {
                text-align: center;
            }
            .load-more-font-size {
                font-size: 1.2rem;
            }
            .load-more-div {
                width: 100%;
                height: 100%;
            }
            .store-logo-margin {
                margin-top: 3rem !important;
            }
            .explore-section-left {
                position: relative;
                top: 41%;
                transform: translateY(-45%);
            }
            .storelogo {
                width: 2rem;
                height: 2rem;
            }
            .phone-call-options {
                position: absolute;
                top: 14%;
            }
            .p-5 {
                padding: 1rem !important;
            }
            .custom-container-3 {
                width: 100%;
            }
            .logo-img {
                width: 200px;
                height: 87px;
                margin-top: -28px;
            }
            .d-sm-none {
                display: none !important;
            }
            .nav-icon {
                display: none;
            }
            .logo-text {
                position: relative;
                top: 34%;
                font-size: 1.3em;
            }
            .menu-bar {
                display: none;
            }
            .special-category {
                display: none;
            }
            .three-bar {
                display: block;
            }
            .three-bar i {
                position: relative;
                top: 37%;
            }
            .top-content {
                min-height: 11em !important;
            }
            .carousel-item img {
                height: 11em;
            }
            .carousel-indicators button {
                padding: 0.2em 0.5em;
            }
            .search-bar {
                display: none !important;
            }
            .top-bar {
                padding: 4% 0%;
            }
            .slider-container-div {
                position: absolute;
                top: 48%;
                left: 39%;
                transform: translate(-50%, -50%);
                color: #fff;
                z-index: 1;
            }
            .slider-container-div h1 {
                font-size: 1.2rem;
            }
            .w-50 {
                width: 144% !important;
                font-size: 0.9rem;
            }
            .slider-container img {
                height: 250px;
                object-fit: cover;
            }
            .mt-custom-1 {
                margin-top: 1.5rem !important;
            }
            .mt-custom-explore-section {
                margin-top: 0.4rem !important;
            }
            .p-custom-explore-section {
                padding: 0.5rem!important;
            }
            .explore-section-left h6 {
                font-size: 0.8rem;
            }
            .explore-section-left h3 {
                font-size: 1.2rem;
            }
            .explore-section-left p {
                font-size: 0.8rem;
            }
            .explore-section-right-image {
                width: 100%;
                height: 130px;
            }
            .middle-slider-section-d-block {
                display: none;
            }
            .middle-slider-section-d-none {
                display: block;
            }
            .middle-slider-right img {
                width: 100%;
                margin-top: 9%;
            }
            .middle-slider-section-left-div {
                margin: auto;
                position: relative;
                top: 0%;
                left: 0%;
                transform: translate(-0%, -0%);
                color: #fff;
                z-index: 1;
            }
            .w-60 {
                width: 100%;
            }
            .custom-p-5 {
                padding: 0rem !important;
            }
            .custom-col-lg-3 {
                width: 50%;
            }
            .custom-col-5 {
                width: 75%;
            }
            .custom-mb {
                margin-bottom: 4rem;
            }
            .show-case-section-right-image {
                width: 100%;
                height: 155px;
            }
            .contact-us-section-left-div {
                margin: 0px;
                margin-top: 3rem;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                z-index: 1;
            }
            .contact-us-section-left-div h3 {
                text-align: center;
            }
            .contact-us-right {
                margin-top: 0px;
                width: 100%;
            }
            .slider-container {
                height: 40vh;
            }
            .slider-container img {
                height: 40vh;
                object-fit: cover;
            }
            nav.link-list {
                position: absolute;
                top: 60%;
                width: 100%;
            }
            nav.link-list a h3 {
                font-size: 0.8rem;
            }
            .find__btn {
                position: relative !important;
                left: 17%;
            }
            .social-icon {
                position: absolute;
                bottom: 52%;
            }
            .slider-social-link-image {
                width: 1.2rem;
            }
            .header-title-cali {
                font-size: 1.3rem !important;
            }
            .header-title-project {
                font-size: 1.3rem !important;
            }
            .header-cali-height {
                height: 8rem !important;
            }
            .scrollToBtn {
                position: fixed;
                width: 85%;
                left: 52%;
                bottom: 60px;
                height: 31px;
                font-size: 13rem;
                z-index: 1;
                cursor: pointer;
                color: #FF4F1C;
            }
        }
        
        @media(min-width: 281px) and (max-width: 575.98px) {
            .branch-title {
                text-align: center;
            }
            .load-more-font-size {
                font-size: 1.2rem;
            }
            .load-more-div {
                width: 100%;
                height: 100%;
            }
            .store-logo-margin {
                margin-top: 3rem !important;
            }
            .explore-section-left {
                position: relative;
                top: 41%;
                transform: translateY(-45%);
            }
            .scrollToBtn {
                position: fixed;
                width: 85%;
                left: 52%;
                bottom: 60px;
                height: 31px;
                font-size: 13rem;
                z-index: 1;
                cursor: pointer;
                color: #FF4F1C;
            }
            .storelogo {
                width: 3rem;
                height: 3rem;
            }
            .phone-call-options {
                position: absolute;
                top: 14%;
            }
            .p-5 {
                padding: 1rem !important;
            }
            .custom-container-3 {
                width: 100%;
            }
            .logo-img {
                width: 200px;
                height: 87px;
                margin-top: -28px;
            }
            .d-sm-none {
                display: none !important;
            }
            .nav-icon {
                display: none;
            }
            .logo-text {
                position: relative;
                top: 34%;
                font-size: 1.3em;
            }
            .menu-bar {
                display: none;
            }
            .special-category {
                display: none;
            }
            .three-bar {
                display: block;
            }
            .three-bar i {
                position: relative;
                top: 37%;
            }
            .top-content {
                min-height: 11em !important;
            }
            .carousel-item img {
                height: 11em;
            }
            .carousel-indicators button {
                padding: 0.2em 0.5em;
            }
            .search-bar {
                display: none !important;
            }
            .top-bar {
                padding: 4% 0%;
            }
            .slider-container-div {
                position: absolute;
                top: 48%;
                left: 39%;
                transform: translate(-50%, -50%);
                color: #fff;
                z-index: 1;
            }
            .slider-container-div h1 {
                font-size: 1.2rem;
            }
            .w-50 {
                width: 144% !important;
                font-size: 0.9rem;
            }
            .slider-container img {
                height: 250px;
                object-fit: cover;
            }
            .mt-custom-1 {
                margin-top: 1.5rem !important;
            }
            .mt-custom-explore-section {
                margin-top: 0.4rem !important;
            }
            .p-custom-explore-section {
                padding: 0.5rem!important;
            }
            .explore-section-left h6 {
                font-size: 0.8rem;
            }
            .explore-section-left h3 {
                font-size: 1.2rem;
            }
            .explore-section-left p {
                font-size: 0.8rem;
            }
            .explore-section-right-image {
                width: 100%;
                height: 130px;
            }
            .middle-slider-section-d-block {
                display: none;
            }
            .middle-slider-section-d-none {
                display: block;
            }
            .middle-slider-right img {
                width: 100%;
                margin-top: 9%;
            }
            .middle-slider-section-left-div {
                margin: auto;
                position: relative;
                top: 0%;
                left: 0%;
                transform: translate(-0%, -0%);
                color: #fff;
                z-index: 1;
            }
            .w-60 {
                width: 100%;
            }
            .custom-p-5 {
                padding: 0rem !important;
            }
            .custom-col-lg-3 {
                width: 50%;
            }
            .custom-col-5 {
                width: 75%;
            }
            .custom-mb {
                margin-bottom: 4rem;
            }
            .show-case-section-right-image {
                width: 100%;
                height: 155px;
            }
            .contact-us-section-left-div {
                margin: 0px;
                margin-top: 3rem;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                z-index: 1;
            }
            .contact-us-section-left-div h3 {
                text-align: center;
            }
            .contact-us-right {
                margin-top: 0px;
                width: 100%;
            }
            .slider-container {
                height: 40vh;
            }
            .slider-container img {
                height: 40vh;
                object-fit: cover;
            }
            nav.link-list {
                position: absolute;
                top: 55%;
                width: 100%;
            }
            nav.link-list a h3 {
                font-size: 1.1rem;
            }
            .find__btn {
                position: relative !important;
                left: 17%;
            }
            .social-icon {
                position: absolute;
                bottom: 52%;
            }
            .slider-social-link-image {
                width: 1.2rem;
            }
            .header-title-cali {
                font-size: 1.6rem !important;
            }
            .header-title-project {
                font-size: 1.6rem !important;
            }
            .header-cali-height {
                height: 8rem !important;
            }
        }
        /* Small devices (landscape phones, 576px and up)*/
        
        @media (min-width: 576px) and (max-width: 767.98px) {
            .branch-title {
                text-align: center;
            }
            .load-more-div {
                width: 100%;
                height: 100%;
            }
            .store-logo-margin {
                margin-top: 3rem !important;
            }
            .explore-section-left {
                position: relative;
                top: 41%;
                transform: translateY(-45%);
            }
            .storelogo {
                width: 3rem;
                height: 3rem;
            }
            .phone-call-options {
                position: absolute;
                top: 14%;
            }
            .p-5 {
                padding: 1rem !important;
            }
            .custom-container-3 {
                width: 100%;
            }
            .logo-img {
                width: 200px;
                height: 87px;
                margin-top: -28px;
            }
            .d-sm-none {
                display: none !important;
            }
            .nav-icon {
                display: none;
            }
            .logo-text {
                position: relative;
                top: 34%;
                font-size: 1.3em;
            }
            .menu-bar {
                display: none;
            }
            .special-category {
                display: none;
            }
            .top-content {
                min-height: 11em !important;
            }
            .carousel-item img {
                height: 11em;
            }
            .carousel-indicators button {
                padding: 0.2em 0.5em;
            }
            .social-icon {
                position: absolute;
                bottom: 61%;
            }
            .slider-social-link-image {
                width: 1.2rem;
            }
            .header-title-cali {
                font-size: 3rem;
            }
            .header-title-project {
                font-size: 3rem;
            }
            .header-cali-height {
                height: 8rem !important;
            }
            .slider-container {
                height: 30vh;
            }
            .slider-container img {
                height: 30vh;
                object-fit: cover;
            }
            .scrollToBtn {
                position: fixed;
                width: 85%;
                left: 52%;
                bottom: 60px;
                height: 31px;
                font-size: 13rem;
                z-index: 1;
                cursor: pointer;
                color: #FF4F1C;
            }
        }
        /* Medium devices (tablets, 768px and up)*/
        
        @media (min-width: 768px) and (max-width: 991.98px) {
            .frame h2 {
                font-size: 13px;
            }
            .branch-title {
                text-align: center;
            }
            .store-logo-margin {
                margin-top: 4rem !important;
            }
            .explore-section-left {
                position: relative;
                top: 32%;
                transform: translateY(-45%);
            }
            .scrollToBtn {
                position: fixed;
                width: 85%;
                left: 55%;
                bottom: 60px;
                height: 40px;
                font-size: 13rem;
                z-index: 1;
                cursor: pointer;
                color: #FF4F1C;
            }
            .storelogo {
                width: 4rem;
                height: 4rem;
            }
            nav.link-list {
                position: absolute;
                top: 55%;
                width: 85%;
            }
            .social-icon {
                position: absolute;
                bottom: 9%;
            }
            .phone-call-options {
                position: absolute;
                top: 14%;
            }
            .header-title-cali {
                font-size: 4rem;
            }
            .p-5 {
                padding: 1rem !important;
            }
            .d-sm-none {
                display: none !important;
            }
            .menu-items-text {
                display: block;
                text-decoration: none;
                color: var(--text-color);
                text-align: center;
                padding: 0.6em 0.7em;
                font-size: 0.5rem;
            }
            .logo-img {
                width: 133px;
                height: 39px;
                margin-top: -17px;
            }
            .custom-col-lg-3 {
                width: 30%;
            }
            .middle-slider-section-d-block {
                display: block;
            }
            .middle-slider-section-d-none {
                display: none;
            }
            .middle-slider-section-left-div {
                margin: 0px;
                margin-top: 100px;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                z-index: 1;
            }
            .custom-middle-slider-p-5 {
                padding: 1rem !important;
            }
            .middle-slider-right img {
                width: 100%;
                margin-top: -14%;
            }
            .custom-p-5 {
                padding: 0rem !important;
            }
            .show-case-section-right-image {
                width: 100%;
                height: 300px;
            }
            .custom-mb {
                margin-bottom: 4rem !important;
            }
            .contact-us-section-left-div {
                margin: 0px;
                margin-top: 47px;
                position: relative;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                z-index: 1;
            }
            .w-60 {
                width: 90%;
            }
            .contact-us-right {
                margin-top: 30px;
                width: 90%;
            }
            .logo-img-updated {
                width: 132px;
                height: 58px;
                /* margin-top: -17px; */
            }
            .header-cali-height {
                height: 10rem !important;
            }
            .slider-container {
                height: 100vh;
            }
            .slider-container img {
                height: 100vh;
                object-fit: cover;
            }
        }
        /* Large devices (desktops, 992px and up)*/
        
        @media (min-width: 992px) and (max-width: 1199.98px) {
            .logo-img-updated {
                width: 132px;
                height: 58px;
                /* margin-top: -17px; */
            }
            .menu-items-text {
                font-size: 1rem;
            }
            .d-sm-none {
                display: block !important;
            }
            .custom-col-lg-2 {
                width: 20% !important;
            }
            .custom-col-xl-2 {
                width: 20% !important;
            }
            .middle-slider-section-d-block {
                display: block;
            }
            .middle-slider-section-d-none {
                display: none;
            }
        }
        /* Extra large devices (large desktops, 1200px and up)*/
        
        @media (min-width: 1200px) {
            .d-sm-none {
                display: block !important;
            }
            .custom-col-lg-2 {
                width: 20% !important;
            }
            .custom-col-xl-2 {
                width: 20% !important;
            }
        }