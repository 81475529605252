@import url('https://fonts.googleapis.com/css2?family=Almendra+Display&family=Noto+Serif+Bengali&family=Poltawski+Nowy:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Bengali&family=Poltawski+Nowy:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
:root {
    /*--main-color:#e20d02c9;*/
    --main-color: #419c08d6;
    --main-color-2: #797482;
    --border-color: 1px solid #0000001f;
    --border-color-hover: 2px solid #0000001f;
    --text-color: #000000cf;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    height: auto !important;
    scroll-behavior: smooth;
}

body {
    font-size: 15px;
    /* font-family: 'Roboto Condensed', sans-serif; */
    font-family: 'Noto Serif Bengali', serif;
    font-family: 'Poltawski Nowy', serif;
    font-weight: 300;
    position: initial !important;
    min-height: initial !important;
    top: auto !important;
    scroll-behavior: smooth;
    background: url(./../images/cali6.jpg);
    background-repeat: repeat;
    background-size: contain;
}

#root {
    height: 100%;
    width: 100%;
    background-color: #ffffffd7;
    /* background-color: #ffffffeb; */
}

::-webkit-scrollbar {
    width: 0.2em;
}

::-webkit-scrollbar-thumb {
    background: var(--main-color-2);
}

.custom-container {
    width: 92%;
    margin: auto;
}

.custom-container-2 {
    width: 90%;
    margin: auto;
}

.custom-container-3 {
    width: 80%;
    margin: auto;
}

.custom-row {
    width: 96%;
    margin: auto;
    display: flex;
}

.custom-col-4 {
    width: 25%;
}

.custom-col-6 {
    width: 50%;
}

.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: left !important;
}

.carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
    background: black;
}

.header-title-cali {
    opacity: 1;
    font-size: 5rem;
    font-weight: 900;
    color: #ffffff91;
}

.header-title {
    opacity: 1;
    font-size: 5rem;
    font-weight: 900;
    color: #ffffff91;
}

.header-cali-height {
    height: 15rem !important;
    width: 100%;
}

.contentOverLay {
    display: block;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
}